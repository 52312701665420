<template>
  <div>
<!--    id 14113 TZ(CN)19511-->
<!--    id 14114 CN-N000024-24-->
    <div v-if="this.Mattress_num > 0">
      <p>购买(CN-N000066-24)套组请选择商品尺码</p>
      <el-table
          border
          :data="Mattress_list"
          header-align="center"
          style="width: 100%">
        <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
        <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
        <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
        <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
        <el-table-column label="购买数量" prop="quantity" width="200" align="center">
          <template slot-scope="{row}">
            <el-input-number
                v-model="row.quantity"
                size="mini"
                :min="0"
                style="width:100px;"
                @change="CDChange(row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="this.Mattress_num1 > 0">
      <p>购买(CN-N000024-24)套组请选择商品尺码</p>
      <el-table
          border
          :data="Mattress_list1"
          header-align="center"
          style="width: 100%">
        <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
        <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
        <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
        <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
        <el-table-column label="购买数量" prop="quantity" width="200" align="center">
          <template slot-scope="{row}">
            <el-input-number
                v-model="row.quantity"
                size="mini"
                :min="0"
                style="width:100px;"
                @change="CDChange1(row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "MattressSizeSelection",
  data(){
    return{
      Mattress_list:[
        {
          name:'健康磁性多功能床垫（小）',
          code:'QC(CN)01091',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-22
        },
        {
          name:'健康磁性多功能床垫',
          code:'CN-T009-01-00',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-21
        }
      ],Mattress_list1:[
        {
          name:'健康磁性多功能床垫（小）',
          code:'QC(CN)01091',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-22
        },
        {
          name:'健康磁性多功能床垫',
          code:'CN-T009-01-00',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-21
        }
      ]
    }
  },
  props: {
    Mattress_num: {
      type: Number,
      default: 0
    },
    Mattress_num1: {
      type: Number,
      default: 0
    }
  },
  watch:{
    'Mattress_num': function (val) {
      if(val===0){
        this.Mattress_list[0].quantity = 0
        this.Mattress_list[1].quantity = 0
      }
    },
  },
  methods:{
    CDChange(){
      let num = 0
      this.Mattress_list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.Mattress_num){
        this.$message.error('床垫所选尺码数量与购买套组数量不符')
      }
      this.$emit('CDChange', this.Mattress_list)
      // console.log(this.HuXi_19508_list)
    },
    CDChange1(){
      let num = 0
      this.Mattress_list1.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.Mattress_num1){
        this.$message.error('床垫所选尺码数量与购买套组数量不符')
      }
      this.$emit('CDChange1', this.Mattress_list1)
      // console.log(this.HuXi_19508_list)
    }
  }
}
</script>

<style scoped>

</style>